/*
 * Usual
 */
@mixin keyframes($animation-name) {
  @-webkit-keyframes #{$animation-name} {
    @content;
  }
  @-moz-keyframes #{$animation-name} {
    @content;
  }
  @keyframes #{$animation-name} {
    @content;
  }
}

@include keyframes(example) {
  100% {
     opacity: 1;
     -webkit-transform: translateY(0);
     -ms-transform: translateY(0);
     transform: translateY(0);
  }
}

@mixin animationValue( $name, $duration , $function, $delay, $count, $state){
 -webkit-animation: $name $duration+s $function $delay+s $count $state;
  animation: $name $duration+s $function $delay+s $count $state;
}
