/*
 * :root
 */
:root {
  // Color
  --header-bg: rgba(255,255,255, 0);

  // Size
  --size-golden: 61.805;
  --size-silver: 70.721;
}


$transition-duration: .3s;


/*
 * Color
 */

/* bootstrap: Update $theme-colors / Add color */
$primary: #000000;
$secondary: #808080;
$dark: #333333;
$neutral: #151515;

/* bootstrap: Add $theme-colors */
$primary-100: mix($primary, #fff, 60%);
$primary-300: mix($primary, #fff, 80%);
$primary-500: $primary;
$primary-700: mix($primary, #000, 75%);
$primary-900: mix($primary, #000, 50%);

$custom-colors: (
  "neutral": $neutral,
  'primary-100': $primary-100,
  'primary-300': $primary-300,
  'primary-500': $primary,
  'primary-700': $primary-700,
  'primary-900': $primary-900,
);


/*
 * Font
 */
$fontfamilies: (
  neutral: ("Helvetica Neue","Helvetica",sans-serif),
  primary: ("Helvetica Neue","Helvetica",sans-serif),
  serif: ("游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif),
);

@function fontfamily($key) {
  @return map-get($fontfamilies, $key);
}

$fontweights: (
  primary: (700),
);
@function fontweight($key) {
  @return map-get($fontweights, $key);
}


/*
 * bootstrap
 */
$grid-gutter-width: 48px;
