@import "variables";
@import "mixin";

@import "~bootstrap/scss/bootstrap-grid.scss";

$theme-colors: map-merge($theme-colors, $custom-colors);

:root,
.row {
  --bs-gutter-x: #{$grid-gutter-width};
  --bs-gutter-y: #{$grid-gutter-width};
  @include media-breakpoint-down(sm) {
    --bs-gutter-x: calc( #{$grid-gutter-width} / 2 );
    --bs-gutter-y: calc( #{$grid-gutter-width} / 2 );
  }
}

// @import "woocommerce";

@import "reboot";
// @import "layout";
// @import "content";
// @import "forms";
// @import "components";
// @import "helpers";
// @import "utilities";
// @import "extened";

// @import "docs"; // sample
@import "customize";
