body {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  margin: 0;
  background-color: #f0f0f0;
}


/*
 * デザイン
 */

#clock {
  #time {
    font-size: 24px;
  }
}











/*
 * 確認用
 */

.checker {
  position: fixed;
  top: 1em;
  right: 1em;

  color: #808080;
  font-size: 12px;
  line-height: 1;
}
